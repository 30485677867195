/* Default styles for images */
.payment-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Default styles for buttons */
.payment-button {
  margin-bottom: 1rem; /* Add spacing between buttons */
  text-align: center;
  padding: 15px; /* Add padding for better click area */
  display: flex; /* Flexbox for vertical alignment */
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between text and image */
  border: 2px solid transparent; /* Add subtle border */
  border-radius: 8px; /* Smooth corners for buttons */
  transition: border-color 0.3s ease-in-out; /* Smooth hover effect */
}

.payment-button:hover {
  border-color: #007bff; /* Highlight border on hover */
}

/* Mobile view: Ensure one button below another */
@media (max-width: 767px) {
  .payment-button {
    width: 90%; /* Take up most of the screen width */
    margin: 0 auto 1rem; /* Center buttons with spacing */
  }

  .payment-image {
    width: 80px; /* Smaller icon size for mobile */
    height: auto;
  }
}

/* Desktop view: Ensure consistent size and grid layout */
@media (min-width: 768px) {
  .payment-image {
    width: 100px; /* Set consistent width */
    height: auto; /* Maintain aspect ratio */
  }

  .payment-button {
    width: 300px; /* Fixed width for buttons */
    margin-bottom: 1rem; /* Add consistent spacing */
  }
}
