/* General modal styles */
.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 500px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/* Modal header styles */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  font-size: 18px;
}

.popup-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Modal content input styles */
.popup-content {
  margin-top: 20px;
}

.popup-content input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Social buttons container */
.social-buttons {
  display: flex;
  justify-content: space-between; /* Evenly distribute icons */
  flex-wrap: wrap; /* Wrap icons to the next row */
  gap: 10px; /* Reduced gap between icon groups */
}

/* Container holding both icon (within a tag) and label */
.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%; /* Divide the width evenly for 3 icons per row */
}

/* Circle icon styles - reduced size */
.circle-icon {
  text-decoration: none;
  background-color: #f0f0f0;
  width: 40px;  /* Reduced size */
  height: 40px; /* Reduced size */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  margin-bottom: 5px; /* Space between icon and label */
}

.circle-icon:hover {
  background-color: #ddd;
}

.circle-icon i {
  font-size: 18px; /* Reduced size of the icon */
  color: #007bff; /* Standard icon color */
}

/* Label styles */
.icon-label {
  font-size: 9px; /* Smaller font size for label */
  color: #333;
  text-align: center;
}

/* Mobile responsiveness: ensure three icons fit in a row */
@media (max-width: 768px) {
  .icon-with-label {
    width: 30%; /* Adjust so 3 icons fit per row */
  }
}

@media (max-width: 576px) {
  .icon-with-label {
    width: 30%; /* Keep same as above for consistency */
  }
}

.icon-image {
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
  object-fit: contain; /* Ensure the image fits without distortion */
}

/* General font settings for the entire component */
body, .modal-content {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 14px; /* Set a smaller base font size */
  color: #333; /* Optional: Ensure readability */
}

/* Modal title font styling */
.modal-title {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 16px; /* Slightly larger for titles */
  font-weight: bold;
}

/* Paragraph font styling */
.modal-body p {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 13px; /* Slightly smaller for descriptions */
  line-height: 1.5; /* Adjust line spacing for readability */
  margin-bottom: 10px;
}

/* Social buttons styling */
.icon-label {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 11px; /* Smaller font for labels */
  color: #333;
  text-align: center;
}

/* Input field styling */
.form-control {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 12px; /* Small font for input fields */
  color: #555; /* Ensure contrast */
}

/* Button font styling */
.btn {
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 13px; /* Smaller font for buttons */
}

